
// Modified https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
function scrollSpy(selector, animationClass, delay){ //need to add class="scroll-anim" to animated scroll element
  const animiationObject = document.querySelector(selector);

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if(delay !== undefined){
          setTimeout(function(){
            animiationObject.classList.add(animationClass);
          }, delay)
        }
        else{
          animiationObject.classList.add(animationClass);
        }
        return;
      }
    });
  },{rootMargin: "-50px"}); //the offset when animation triggers

  observer.observe(document.querySelector(selector));
}